import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    discountFounding: "3. Discount / Founding",
    discount: "Discount:",
    founding: "Founding:",
    couponCode: "Coupon code:",
    couponCodePlaceholder: "Podaj kod kuponu",
    applyCode: "Apply code",
    levelVerification: "Level verification",
    levelVerificationNormalText:
      "Course you want to register requires additonal language level verification. Please upload valid documents if you do have them.",
    levelVerificationSmallText:
      "On a case-by-case basis, the LcL Institute reserves the right to classify participants if there is a need for clarification of their level of prerequisites and to allocate them to a language course that is suitable for them.",
    paymentMethod: "5. Payment method",
    personalData: "Personal data",
    title: "Title:",
    name: "Name:",
    firstNamePlaceholder: "Podaj imię",
    surname: "Surname:",
    lastNamePlaceholder: "Podaj nazwisko",
    street: "Street:",
    streetPlaceholder: "np. Downing Street",
    houseNumber: "House number:",
    numberPlaceholder: "np. 10",
    staircase: "Staircase:",
    floor: "Floor:",
    door: "Door:",
    city: "City:",
    cityPlaceholder: "ex. New York",
    postalCode: "Postal code:",
    postalCodePlaceholder: "ex. SW1A 2AA",
    nationality: "Nationality:",
    dateOfBirth: "Date of birth:",
    dateOfBirthPlaceholder: "Podaj datę urodzenia",
    placeOfBirth: "Place of birth:",
    proceedToPayment: "Proceed to payment",
    uploadFile: "Upload confirmation document",
    cancellationPolicy: "Cancellation policy",
    joinACourse: "Join a course",
    orderHeadingtext:
      "We will verify your application before joining a course. You will receive summary of information to your mailing box after finilizing the course booking payment.",
    academicTitle: "Academic Title:",
    sex: "Sex:",
    female: "Female",
    male: "Male",
    notSpecified: "Not specified",
    timesAWeek: "times a week",
    timesAWeekEnd: ["mornings", "afternoon", "evening"],
    inHouse: "In-house courses",
    online: "Online courses",
    chooseOption: "Choose option:",
    titlesOptions: ["Choose option:", "Mr", "Mrs"],
    remove: "Remove",
    couponAdded: "Coupon code added",
    saleDiscountInfo:
      "We're verifying your discount and we''ll reach out to you with the adjusted invoice.",
    discountBoldInfo:
      "Discounts and Foundings cannot be combined with Coupon code.",
    discountNormalInfo:
      "Aenean sollicitudin mattis purus, ac elementum quam aliquet vel. Pellentesque ac nibh orci. Vestibulum volutpat condimentum iaculis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.",
    rebateOptions: [
      "Choose option:",
      "I'm a student and bring a valid student card.",
      "I'm recieving financial support for the course.",
      "I'm booking several LcL services.",
    ],
    eligibilityRequirements: [
      "I meet the eligibility requirements for this course.",
      "I need a placement test.",
    ],
    coursePreferencesTitle: "Course preferences",
    coursePreferencesQuestion:
      "Which time frames for the classes are suitable for you?",
    coursePreferencePlace: "Where would you like to attend the classes?",
    coursePreferencesOptions: ["At the LcL Institute", "Online"],
    orderSteps: [
      "Personal data and contact",
      "Course preferences / Discount",
      "Course details",
    ],
    paymentInProgress: "Payment in progress",
    prevStep: "Previous step",
    nextStep: "Next step",
  },
  [Languages.German]: {
    discountFounding: "3. Rabatt / Förderung",
    discount: "Rabatt:",
    founding: "Gründung:",
    couponCode: "Gutscheincode:",
    couponCodePlaceholder: "Geben Sie den Gutscheincode ein",
    applyCode: "Code anwenden",
    levelVerification: "Level-Überprüfung",
    levelVerificationNormalText:
      "Der Kurs, für den Sie sich anmelden, benötigt eine zusätzliche Prüfung Ihres Sprachniveaus. Laden Sie hier Ihre Sprachnachweise hoch, falls vorhanden.",
    levelVerificationSmallText:
      "Fallweise behält sich das LcL-Institut das Recht vor, Teilnehmende bei Klärungsbedarf ihres Voraussetzungsniveaus einzustufen und sie einem für sie geeigneten Sprachkurs zuzuordnen.",
    paymentMethod: "5. Payment method",
    personalData: "Persönliche Daten",
    title: "Anrede:",
    name: "Vorname:",
    firstNamePlaceholder: "z.B. Max",
    surname: "Nachname:",
    lastNamePlaceholder: "z.B. Mustermann",
    street: "Straße:",
    streetPlaceholder: "z.B. Downing Street",
    houseNumber: "Hausnummer:",
    numberPlaceholder: "z.B. 10",
    staircase: "Stiege:",
    floor: "Stockwerk:",
    door: "Tür:",
    city: "Stadt:",
    cityPlaceholder: "z.B. New York",
    postalCode: "Postleitzahl:",
    postalCodePlaceholder: "z.B. SW1A 2AA",
    nationality: "Staatsangehörigkeit:",
    dateOfBirth: "Geburtsdatum:",
    dateOfBirthPlaceholder: "z.B. 10.10.2000",
    placeOfBirth: "Geburtsort:",
    proceedToPayment: "Weiter zur Zahlung",
    uploadFile: "Bestätigung hochladen",
    cancellationPolicy: "Anmeldung",
    joinACourse: "Sie nehmen an diesem Kurs teil",
    orderHeadingtext:
      "Wir prüfen Ihre Anmeldung. Ist sie korrekt, bekommen Sie Ihre Kursrechnung mit einem Link zum Zahlen an die angegebene E-Mail-Adresse.",
    academicTitle: "Akademischer Titel:",
    sex: "Geschlecht:",
    female: "Weiblich",
    male: "Männlich",
    notSpecified: "Unbestimmt",
    timesAWeek: "mal die Woche",
    timesAWeekEnd: ["vormittags", "nachmittags", "abends"],
    inHouse: "Vor Ort am LcL-Institut",
    online: "Online",
    chooseOption: "Option wählen:",
    titlesOptions: ["Option wählen", "Herr", "Frau"],
    remove: "Löschen",
    couponAdded: "Gutscheincode hinzugefügt",
    saleDiscountInfo:
      "Wir verifizieren Ihre Rabattierung und melden uns bei Ihnen mit der angepassten Rechnung.",
    discountBoldInfo:
      "Rabatte können nicht mit Gutscheincode kombiniert werden.",
    discountNormalInfo:
      "Aenean sollicitudin mattis purus, ac elementum quam aliquet vel. Pellentesque ac nibh orci. Vestibulum volutpat condimentum iaculis. Pellentesker Bewohner morbi tristique senectus et netus et malesuada fames.",
    rebateOptions: [
      "Option wählen:",
      "Ich bin StudentIn und bringe meinen gültigen Studentenausweis mit.",
      "Ich habe eine Förderung für den Kurs.",
      "Ich buche mehrere LcL-Dienstleistungen.",
    ],
    eligibilityRequirements: [
      "Ich erfülle die Teilnahmevoraussetzungen für diesen Kurs.",
      "Ich brauche einen Einstufungstest.",
    ],
    coursePreferencesTitle: "Kurspreferenzen",
    coursePreferencesQuestion: "Welche Zeiträume für den Kurs passen Ihnen?",
    coursePreferencePlace: "Wo möchten Sie den Kurs besuchen?",
    coursePreferencesOptions: ["Am LcL-Institut", "Online"],
    orderSteps: [
      "Persönliche Daten",
      "Rabattierung, Förderungen & Kursvorlieben",
      "Kursdetails",
    ],
    paymentInProgress: "Laufende Zahlungen",
    prevStep: "Vorheriger Schritt",
    nextStep: "Nächster Schritt",
  },
};

export { translations };
